// @flow

import React, { Component } from "react";
import { Admin, Resource } from "react-admin";
import ApolloClient from "apollo-boost";
import buildGraphQLProvider from "ra-data-graphql";

// Icons
import UserIcon from "@material-ui/icons/Group";

import schemaData from "./queries/schema.json";
import authBuilder from "./authBuilder";
import queryBuilder from "./provider";

// Users
import UserList from "./models/user/list";
import UserEdit from "./models/user/edit";

const apiHost = process.env.REACT_APP_API_HOST;
if (apiHost == null) {
  throw new Error("API host cannot be null.");
}

const { __schema: schema } = schemaData;

const client = new ApolloClient({
  uri: `${apiHost}/admin/graphql`,
  credentials: "include",
});

const authProvider = authBuilder();

type Property = {};

type State = {
  dataProvider: any
};

class App extends Component<Property, State> {
  constructor() {
    super();
    this.state = { dataProvider: null };
  }
  componentDidMount() {
    buildGraphQLProvider({
      client,
      buildQuery: queryBuilder,
      introspection: { schema },
    })
      .then(dataProvider => this.setState({ dataProvider }));
  }

  render() {
    const { dataProvider } = this.state;

    if (!dataProvider) {
      return <div>Loading</div>;
    }

    return (
      <Admin
        authProvider={authProvider}
        dataProvider={dataProvider}
      >
        <Resource
          name="users"
          list={UserList}
          edit={UserEdit}
          icon={UserIcon}
        />
      </Admin>
    );
  }
}

export default App;
