// @flow

import ApolloClient from "apollo-boost";
import gql from "graphql-tag";

function generateUUID(): string {
  const s4 = () => Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

function getUUID(): string {
  const currentUUID = localStorage.getItem("deviceId");
  if (currentUUID == null) {
    const newUUID = generateUUID();
    localStorage.setItem("deviceId", newUUID);
    return newUUID;
  }

  return currentUUID;
}

const apiHost = process.env.REACT_APP_API_HOST;
if (apiHost == null) {
  throw new Error("API host cannot be null.");
}

const apolloClient = new ApolloClient({
  uri: `${apiHost}/admin/graphql`,
  credentials: "include",
});

async function login(username: ?string, password: ?string): Promise<void> {
  if (username == null || password == null) {
    throw new Error();
  }

  await apolloClient.mutate({
    mutation: gql`mutation loginUser(
      $email: String!,
      $password: String!,
      $deviceId: String!,
      $description: String!,
      $language: ISOLanguage!
    ){
      authentication {
        loginEmail(
          email: $email,
          password: $password,
          deviceId: $deviceId,
          platform: web,
          description: $description,
          language: $language
        ) {
          userId
        }
      }
    }`,
    variables: {
      email: username,
      password: password,
      deviceId: getUUID(),
      description: navigator.userAgent,
      language: navigator.language.split("-")[0],
    },
  });
}

async function logout(): Promise<void> {
  try {
    await apolloClient.mutate({
      mutation: gql`mutation logoutUser($deviceId: String!) {
        authentication {
          logout(deviceId: $deviceId, platform: web)
        }
      }`,
      variables: { deviceId: getUUID() },
    });
  } catch {
    // Rejected logout because user is already logged out
  }
}

async function checkError(status: number): Promise<void> {
  if (status === 400 || status === 401 || status === 403) {
    throw new Error();
  }
}

async function check(): Promise<void> {
  const response = await apolloClient.query({
    query: gql`query isLoggedIn {
      authentication {
        isLoggedIn
      }
    }`,
    fetchPolicy: 'network-only'
  });

  if (!response.data.authentication.isLoggedIn) {
    throw new Error();
  }
}

export default function authBuilder() {
  return {
    login: params => login(params.username, params.password),
    logout: params => logout(),
    checkAuth: params => check(params.status),
    checkError: error => checkError(error.status),
    getPermissions: params => Promise.resolve()
  };
}
