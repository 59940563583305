// @flow

import React from "react";
import {
  List,
  Datagrid,
  Filter, TextInput, NullableBooleanInput, CheckboxGroupInput, DateInput,
  EmailField, TextField, DateField, EditButton,
} from "react-admin";

const languagesChoices = [
  { id: "fr", name: "French" },
  { id: "en", name: "English" },
];

const parseDateTime = (date: ?string) => {
  if (date == null) {
    return "";
  }

  return new Date(date);
};

const UserFilter = props => (
  <Filter {...props}>
    <TextInput source="firstName" />
    <TextInput source="lastName" />
    <TextInput source="email" />
    <TextInput source="phoneNumber" />
    <TextInput source="lastIpAddress" />
    <NullableBooleanInput source="isLocked" />
    <DateInput source="lastAuthBefore" parse={parseDateTime} />
    <DateInput source="lastAuthAfter" parse={parseDateTime} />
    <NullableBooleanInput source="isActive" />
    <NullableBooleanInput source="isDeleted" />
    <NullableBooleanInput source="fullOnboardingStatus" />
    <CheckboxGroupInput source="languages" choices={languagesChoices} />
    <NullableBooleanInput source="isUsed" />
    <DateInput source="createdBefore" parse={parseDateTime} />
    <DateInput source="createdAfter" parse={parseDateTime} />
  </Filter>
);

const UserList = props => (
  <List
    title="All users"
    {...props}
    sort={{ field: "creationTimestamp", order: "DESC" }}
    filters={<UserFilter />}
    perPage={20}
  >
    <Datagrid>
      <TextField source="firstName" />
      <TextField source="lastName" />
      <EmailField source="email" />
      <DateField source="creationTimestamp" showTime />
      <EditButton />
    </Datagrid>
  </List>
);

export default UserList;
