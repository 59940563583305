// @flow

import userQueryBuilder from "./models/user/provider";

type Obj = {[string]: any};

const providers: {[string]: ((raFetchType: string, params: Obj) => (?Obj))} = {
  users: userQueryBuilder(),
};

export default function queryBuilder() {
  return (raFetchType: string, resourceName: string, params: Obj) => {
    if (Object.keys(providers).includes(resourceName)) {
      return providers[resourceName](raFetchType, params);
    }

    return null;
  };
}
