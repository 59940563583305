// @flow

import React from "react";
import {
  Edit, TabbedForm, FormTab,
  TextInput, DateInput, NumberInput,
  SelectInput, BooleanInput, CheckboxGroupInput,
  required
} from "react-admin";

const UserName = ({ record }: {record: ?{id: string, firstName: ?string, lastName: ?string}}) => {
  let name = "";
  if (record != null) {
    const nameParts: Array<string> = [];

    if (record.firstName != null) {
      nameParts.push(record.firstName);
    }

    if (record.lastName != null) {
      nameParts.push(record.lastName);
    }

    if (nameParts.length === 0) {
      nameParts.push(record.id);
    }

    name = nameParts.join(" ");
  } else {
    name = "New user";
  }

  return (<span>{name}</span>);
};

const genderChoices = [
  { id: "male", name: "Male" },
  { id: "female", name: "Female" },
];

const maritalStatusChoices = [
  { id: "single", name: "single" },
  { id: "married", name: "married" },
  { id: "separated", name: "separated" },
  { id: "divorced", name: "divorced" },
  { id: "widowed", name: "widowed" },
];

const sexualOrientationChoices = [
  { id: "male", name: "Male" },
  { id: "female", name: "Female" },
];

const moodChoices = [
  { id: "friendly", name: "friendly" },
  { id: "serious", name: "serious" },
  { id: "open", name: "open" },
];

// const permissionsChoices = [
//   { id: "superadmin", name: "Super Admin" },
// ];

const UserEdit = props => (
  <Edit title={<UserName record={props} />} {...props}>
    <TabbedForm>
      <FormTab label="user">
        <TextInput disabled source="id" />
        <TextInput source="firstName" validate={required()} />
        <TextInput source="lastName" validate={required()} />
        <TextInput source="email" type="email" validate={required()} />
        <TextInput source="phoneNumber" type="phone" />
        <TextInput disabled source="fullOnboardingStatus" />
        <TextInput disabled source="language" />
        <TextInput disabled source="lastIpAddress" />
        <TextInput disabled source="creationTimestamp" />
      </FormTab>

      <FormTab label="profile">
        <TextInput label="nickname" source="profile.nickname" />
        <DateInput label="birthdate" source="profile.birthdate" validate={required()} />
        <NumberInput label="height" source="profile.height" step={1} validate={required()} />
        <TextInput label="country" source="profile.country" validate={required()} />
        <TextInput label="postalCode" source="profile.postalCode" />
        <TextInput label="city" source="profile.city" />
        <SelectInput label="gender" source="profile.gender" choices={genderChoices} validate={required()} />
        <TextInput label="job" source="profile.job" />
        <TextInput disabled label="zodiacSign" source="profile.zodiacSign" />
        {/* <ReferenceArrayInput label="interests" source="profile.interests" /> */}
      </FormTab>

      <FormTab label="bachelor">
        <BooleanInput source="isBachelor" />
        <SelectInput
          label="maritalStatus"
          source="profile.bachelor.maritalStatus"
          choices={maritalStatusChoices}
        />
        <BooleanInput label="hasKids" source="profile.bachelor.hasKids" />
        <CheckboxGroupInput
          label="sexualOrientation"
          source="profile.bachelor.sexualOrientation"
          choices={sexualOrientationChoices}
        />
        <SelectInput
          label="mood"
          source="profile.bachelor.mood"
          choices={moodChoices}
        />
        <TextInput multiline label="bio" source="profile.bachelor.bio" />
        <BooleanInput label="isDisplayingMood" source="profile.bachelor.isDisplayingMood" />
      </FormTab>

      <FormTab label="chaperon">
        <BooleanInput source="isChaperon" />
        <TextInput multiline label="bio" source="profile.chaperon.bio" />
      </FormTab>

      <FormTab label="admin">
        <BooleanInput source="isAdmin" />
        {/* <CheckboxGroupInput
          source="permissions"
          choices={permissionsChoices}
        /> */}
      </FormTab>
    </TabbedForm>
  </Edit>
);

export default UserEdit;
