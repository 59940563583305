// @flow

import gql from "graphql-tag";

const userFragment = `
id
firstName
lastName
email
phoneNumber
fullOnboardingStatus
language
lastIpAddress
creationTimestamp
isAdmin
permissions
profile {
  nickname
  birthdate
  height
  country
  postalCode
  city
  gender
  job
  zodiacSign
  interests {
    id
    name
  }
  roles
  bachelor {
    maritalStatus
    hasKids
    sexualOrientation
    mood
    bio
    isDisplayingMood
  }
  chaperon {
    bio
  }
}
`;

export default function queryBuilder(): (
  (raFetchType: string, params: {[string]: any}) => (?{[string]: any})
) {
  return (raFetchType: string, params: {[string]: any}) => {
    switch (raFetchType) {
      case "GET_LIST":
        return {
          query: gql`query users($first: Int!, $page: Int!, $filter: UserFilter, $sorting: UserSorting!) {
            data: user {
              users(
                first: $first,
                after: null,
                page: $page,
                filter: $filter,
                sorting: $sorting
              ) {
                edges {
                  node {
                    id
                    firstName
                    lastName
                    email
                    phoneNumber
                    fullOnboardingStatus
                    language
                    lastIpAddress
                    creationTimestamp
                  }
                }
                totalCount
              }
            }
          }`,
          variables: {
            first: params.pagination.perPage,
            page: params.pagination.page,
            filter: params.filter,
            sorting: {
              field: params.sort.field,
              order: params.sort.order.toLowerCase(),
            },
          },
          parseResponse: response => ({
            data: response.data.data.users.edges.map(edge => edge.node),
            total: response.data.data.users.totalCount,
          }),
        };

      case "GET_ONE":
        return {
          query: gql`query user($id: UUID!) {
            data: user {
              user(userId: $id) {
                ${userFragment}
              }
            }
          }`,
          variables: params,
          parseResponse: response => ({
            data: {
              isBachelor: response.data.data.user.profile.bachelor != null,
              isChaperon: response.data.data.user.profile.chaperon != null,
              ...response.data.data.user,
            },
          }),
        };

      case "UPDATE":
        return {
          query: gql`mutation updateUser(
            $userId: UUID!,
            $email: String!,
            $personalInformation: PersonalInformation,
            $location: Location,
            ${params.data.isBachelor ?
    `$maritalStatus: MaritalStatus!,
    $hasKids: Boolean!,
    $sexualOrientation: [SexualOrientation!]!,
    $mood: Mood,
    $bachelorBio: String,` : ""}
            ${params.data.isChaperon ? "$chaperonBio: String," : ""}
            $isAdmin: Boolean!,
            $permissions: [Permission!],
            $phoneNumber: String
          ) {
            email: user {
              setEmailForUserId(userId: $userId, email: $email)
            }
            profile: profile {
              setForUserId(
                userId: $userId,
                personalInformation: $personalInformation,
                location: $location,
                interests: null
              ) { userId }
            }
            ${params.data.isBachelor ?
    `bachelorProfile: profile {
      setBachelorProfileForUserId(
        userId: $userId,
        maritalStatus: $maritalStatus,
        hasKids: $hasKids,
        sexualOrientation: $sexualOrientation,
        mood: $mood,
        bio: $bachelorBio
      ) { userId }
    }` :
    "removeBachelorProfile: profile { removeBachelorProfileForUserId(userId: $userId) }"}
    ${params.data.isChaperon ?
    `chaperonProfile: profile {
      setChaperonProfileForUserId(
        userId: $userId,
        bio: $chaperonBio
      ) { userId } }` : ""}
              admin: user {
                setAdminAccessForUserId(userId: $userId, isAdmin: $isAdmin, permissions: $permissions)
              }
              phoneNumber: user {
                setPhoneForUserId(userId: $userId, phoneNumber: $phoneNumber) {
                  ${userFragment}
                }
              }
          }`,
          variables: {
            userId: params.id,
            email: params.data.email,
            phoneNumber: params.data.phoneNumber,
            isAdmin: params.data.isAdmin,
            permissions: params.data.isAdmin ? (params.data.permissions || []) : null,
            personalInformation: {
              firstName: params.data.firstName,
              lastName: params.data.lastName,
              gender: params.data.profile.gender,
              job: params.data.profile.job,
              nickname: params.data.profile.nickname,
              height: params.data.profile.height,
              birthdate: params.data.profile.birthdate,
            },
            location: {
              country: params.data.profile.country,
              postalCode: params.data.profile.postalCode,
              city: params.data.profile.city,
            },
            maritalStatus: params.data.profile.bachelor ?
              (params.data.profile.bachelor.maritalStatus || false) : false,
            hasKids: params.data.profile.bachelor ?
              (params.data.profile.bachelor.hasKids || false) : false,
            sexualOrientation: params.data.profile.bachelor ?
              (params.data.profile.bachelor.sexualOrientation || []) : [],
            mood: params.data.profile.bachelor ?
              params.data.profile.bachelor.mood : null,
            bachelorBio: params.data.profile.bachelor ?
              params.data.profile.bachelor.bio : null,
            chaperonBio: params.data.profile.chaperon ?
              params.data.profile.chaperon.bio : null,
          },
          parseResponse: response => ({
            data: response.data.phoneNumber.setPhoneForUserId,
          }),
        };

      case "DELETE":
        return {
          query: gql`mutation deleteUser($userId: UUID!) {
            user {
              deleteByUserId(userId: $userId)
            }
          }`,
          variables: {
            userId: params.id,
          },
          parseResponse: () => ({
            data: {
              id: params.id,
            },
          }),
        };

      case "DELETE_MANY":
        return {
          query: gql`mutation deleteUsers($userIds: [UUID!]!) {
              user {
                deleteByUserIds(userIds: $userIds)
              }
            }`,
          variables: {
            userIds: params.ids,
          },
          parseResponse: () => ({
            data: [
              params.ids,
            ],
          }),
        };

      default:
        return null;
    }
  };
}
